













































import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import moment from 'moment'
import {Fragment} from 'vue-fragment'
import HomepageCardHeader from '@/components/cards/homepage/HomepageCardHeader.vue'
import HomepageCardContent from '@/components/cards/homepage/HomepageCardContent.vue'
import {NetworkStatistics} from '@/helpers/ShrikeHelper'

type N3Statistics = {
  title: string
  subtitle: string
  statistics: Statistics[]
}

type Statistics = {
  title: string
  value: string
  weekTitle: string
  weekValue: string
}

@Component({
  components: {
    HomepageCardHeader,
    HomepageCardContent,
    PercentageProgress,
    Fragment,
  },
})
export default class N3StatisticsCard extends Vue {
  @Prop({type: Object, required: true})
  statistics!: NetworkStatistics

  items: N3Statistics = {
    title: this.$translate('components.cards.N3StatisticsCard.title'),
    subtitle: this.$translate('components.cards.N3StatisticsCard.subtitle'),
    statistics: [],
  }

  async mounted() {
    await this.getData()
  }

  getImageUrl(index: number) {
    switch (index) {
      case 0:
        return require('@/assets/img/networkContracts.svg')
      case 1:
        return require('@/assets/img/addresses.svg')
      case 2:
        return require('@/assets/img/allTimeTransactions.svg')
    }
  }

  private async getData() {
    this.items.subtitle = this.$translate(
      'components.cards.N3StatisticsCard.subtitle',
      {
        date: moment(new Date()).format('ddd, HH:mm'),
      }
    )

    const formattedCurrentWeekContracts = this.statistics.currentWeekContracts.toLocaleString(
      'eng-us'
    )
    const formattedCurrentWeekAddresses = this.statistics.currentWeekAddresses.toLocaleString(
      'eng-us'
    )
    const formattedCurrentWeekTransactions = this.statistics.currentWeekTransactions.toLocaleString(
      'eng-us'
    )

    this.items.statistics = [
      {
        title: this.$translate(
          'components.cards.N3StatisticsCard.networkContracts.title'
        ),
        value: this.statistics.totalContracts.toLocaleString('eng-us') ?? '',
        weekTitle: this.$translate(
          'components.cards.N3StatisticsCard.networkContracts.weekTitle'
        ),
        weekValue: formattedCurrentWeekContracts,
      },
      {
        title: this.$translate(
          'components.cards.N3StatisticsCard.addresses.title'
        ),
        value: this.statistics.totalAddresses.toLocaleString('eng-us') ?? '',
        weekTitle: this.$translate(
          'components.cards.N3StatisticsCard.addresses.weekTitle'
        ),
        weekValue: formattedCurrentWeekAddresses,
      },
      {
        title: this.$translate(
          'components.cards.N3StatisticsCard.allTimeTransactions.title'
        ),
        value: this.statistics.totalTransactions.toLocaleString('eng-us') ?? '',
        weekTitle: this.$translate(
          'components.cards.N3StatisticsCard.allTimeTransactions.weekTitle'
        ),
        weekValue: formattedCurrentWeekTransactions,
      },
    ]
  }
}
