import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {BlockchainHourlyCache} from '@/model/resource/BlockchainHourlyCache'

@HttpExclude()
export class BlockchainHourlyCacheCollection extends ExpansibleCollection<
  BlockchainHourlyCache
> {
  resource?: IBlockchainHourlyCacheCollectionResourcesHolder
  @RequestExpose() idBlockchainVersionFk: number | null = null
  @RequestExpose() startDate: string | null = null
  @RequestExpose() endDate: string | null = null
  @RequestExpose() minContractCount: number | null = null
  @RequestExpose() maxContractCount: number | null = null
  @RequestExpose() minAddressCount: number | null = null
  @RequestExpose() maxAddressCount: number | null = null
  @RequestExpose() minTransactionCount: number | null = null
  @RequestExpose() maxTransactionCount: number | null = null
  @RequestExpose() limit: number | null = null
  @RequestExpose() withoutTotal: boolean | null = null

  constructor() {
    super(BlockchainHourlyCache)
  }

  queryAsPage() {
    return this.listBlockchainHourlyCache()
  }

  async listBlockchainHourlyCache() {
    return await Request.get(`/client/blockchain-hourly-cache`, {
      params: this.params,
    })
      .name('listBlockchainHourlyCache')
      .as(this)
      .getResponse()
  }

  async listExportBlockchainHourlyCache() {
    return await Request.get(`/client/blockchain-hourly-cache/export`, {
      params: this.params,
    })
      .name('listExportBlockchainHourlyCache')
      .as(this)
      .getResponse()
  }
}

export interface IBlockchainHourlyCacheCollectionResourcesHolder {}
